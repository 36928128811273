<template>
  <el-dialog
    :title=" rowItem.id ? '编辑附件' : '新增附件'"
    :visible.sync="fileVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="附件名称" prop="name">
          <el-input class="w300"   maxlength="15" show-word-limit v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="附件描述" prop="attachmentDescribe">
          <el-input class="w300" maxlength="30" show-word-limit v-model="ruleForm.attachmentDescribe"></el-input>
        </el-form-item>
        <el-form-item v-show="false" label="是否必传" prop="isRequired">
          <el-radio-group disabled v-model="ruleForm.isRequired">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  import { saveEnclosure, updateEnclosure } from "@/api/homeCasedCareServices"
  export default {
    name: "addFiles",
    props: {
      fileVisible: {
        type: Boolean,
        default: false
      },
      rowItem: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data() {
      return {
        ruleForm: {
          "attachmentDescribe": "",
          "isRequired": 0,
          "name": ""
        },
        rules: {
          name: [
            { required: true, message: '请输入附件名称', trigger: 'blur' },
            {  max: 15, message: '附件名称最多15个字符', trigger: 'blur' }
          ],
          isRequired: [
            { required: true, message: '请选择是否必填', trigger: 'blur' },
          ]
        }
      }
    },
    mounted() {
      if(this.rowItem && this.rowItem.id ) {
        this.ruleForm = JSON.parse(JSON.stringify(this.rowItem))
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:fileVisible', false)
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.ruleForm.id) {
              this.updateEnclosurePost()
            }else {
              this.saveEnclosurePost()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      /*新增*/
      async saveEnclosurePost() {
        const res = await saveEnclosure(this.ruleForm)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('updateList')
        }
        this.handleClose()
      },
      /*编辑*/

      async  updateEnclosurePost() {
        const res = await  updateEnclosure(this.ruleForm)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('updateList')
        }
        this.handleClose()
      },
    }
  }
</script>

<style scoped>
  .w300 {
    width: 300px;
  }
</style>
